<template>
  <div>
    <SidebarLeft :class="{'sidebar-left-show': sidebars.leftSidebar}">
      <FilterProductsForm v-if="isLeftSidebarVisible" @slide="isLeftSidebarVisible = $event" />
    </SidebarLeft>

    <TableContainer
      ref="table-container"
      :columns="tableData"
      :counter-filter="searchCount"
      :is-archive="$can('edit', 'directory-item-archive')"
      :is-archiving="$can('edit', 'directory-item-trash')"
      :is-loading-items="$store.state.other.isLoadingItems"
      :is-loading-new-item="$store.state.other.isLoadingNewItem"
      :is-remove-from-archive="$can('edit', 'directory-item-delete')"
      :rows="reformatTableData"
      :show-add-button="$can('edit', 'directory-item-create')"
      :sorting-fields="sortingFields"
      :title="$store.state.products.section.title || $t('Products')"
      name="products"
      title-add-button="Продукт"
      @show-right-sidebar="() => $store.commit('sidebars/changeRightSidebar', true)"
      @on-selected="selectItem"
      @delete-item="deleteItem"
      @archive-items="archiveItems"
      @update-data="updateData"
      @copy-item="copyItem"
      @return-from-archive="returnFromArchiveProducts"
      @next-page="loadNewData"
      @on-change-archive="
        () => {
          $store.commit('products/changePage', 1)
          if ($route.name !== 'products') $router.push({name: 'products'})
        }
      "
    >
      <template slot="head-buttons">
        <template
          v-if="$store.state.products.selectedItemsForDelete.length === 0 && $can('edit', 'directory-item-export')"
        >
          <zem-link v-if="!downloadUrl" :disabled="unloadingProcess" class="zem-link-mobile" @click="uploadData">
            <external-link-icon class="mr-1" size="16" />
            <span v-if="!unloadingProcess" class="mobile-d-none">{{ $t('Export') }}</span>
            <span v-else class="mobile-d-none">{{ $t('Export') }}...</span>
          </zem-link>
          <zem-link v-else class="mt-0 zem-link-mobile" @click="downloadData">
            <download-icon class="mr-1" size="16" />
            <span>{{ $t('Download') }}</span>
          </zem-link>
          <a id="download-file" class="d-none"></a>
        </template>
      </template>
    </TableContainer>

    <SidebarRight :class="{'sidebar-right--show': sidebars.rightSidebar}">
      <Product ref="product" :products="products" />
    </SidebarRight>
  </div>
</template>

<script>
import SidebarRight from '../components/SidebarRight'
import FilterProductsForm from '@/components/FilterProductsForm'
import Product from '@/components/Product'
import TableContainer from '@/components/TableContainer'
import {eventBus} from '@/main'
import {errorHandler} from '@/assets/scripts/scripts'
import ProductsService from '@/services/products.service'
import SidebarLeft from '@/components/SidebarLeft.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import {DownloadIcon, ExternalLinkIcon} from 'vue-feather-icons'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemIcon from '@/components/ui/ZemIcon.vue'
import OtherService from '@/services/other.service'
import ZemTable from '@/components/ui/ZemTable.vue'

export default {
  components: {
    ZemTable,
    ZemIcon,
    ZemButton,
    ZemLink,
    Product,
    SidebarLeft,
    SidebarRight,
    FilterProductsForm,
    TableContainer,
    ExternalLinkIcon,
    DownloadIcon,
  },
  data() {
    return {
      isLeftSidebarVisible: true,
      sortingFields: ['sku', 'title', 'updated_at'],
      tableData: [],
      unloadingProcess: false,
      downloadUrl: '',
    }
  },

  computed: {
    reformatTableData() {
      return this.products.map(item => {
        const {data} = item['priceList']
        const newPrices = {
          ...item,
          price1: data[0]['price'].currency.value,
          price2: data[0]['price_up'],
          price3: data[0]['price_customer'].currency.value,
        }

        if (data.length > 1) {
          newPrices['price4'] = data[1]['price'].currency.value
          newPrices['price5'] = data[1]['price_up']
          newPrices['price6'] = data[1]['price_customer'].currency.value
        }

        return newPrices
      })
    },
    searchCount() {
      return this.$store.state.products.searchCount
    },
    products() {
      return this.$store.state.products.elements
    },
    sidebars() {
      return this.$store.state.sidebars
    },
  },

  created() {
    this.tableData = [
      {
        label: this.$t('Article'),
        field: 'sku',
        sortField: 'sku',
        showInTable: true,
        sort: 0,
      },
      {
        label: this.$t('Name'),
        field: 'title',
        sortField: 'title',
        showInTable: true,
        sort: 0,
      },
      {
        label: this.$t('Units of measurement'),
        field: 'unit.data.name',
        sortField: 'unit',
        showInTable: true,
        sort: 0,
      },
      {
        label: 'Цена ЗЧТ: Себестоимость',
        field: 'price1',
        sortField: 'priceList',
        showInTable: false,
        sort: 0,
      },
      {
        label: 'Цена ЗЧТ: Наценка, %',
        field: 'price2',
        sortField: 'priceList',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Цена ЗЧТ: Для заказчика',
        field: 'price3',
        sortField: 'priceList',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Цена ГВЛ: Себестоимость',
        field: 'price4',
        sortField: 'priceList',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Цена ГВЛ: Наценка, %',
        field: 'price5',
        sortField: 'priceList',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Цена ГВЛ: Для заказчика',
        field: 'price6',
        sortField: 'priceList',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Дата изменения',
        type: 'datetime',
        field: 'updated_at',
        sortField: 'updated_at',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Кто изменил',
        field: 'updatedBy.data.name',
        sortField: 'updatedBy',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Кто создал',
        field: 'createdBy.data.name',
        sortField: 'createdBy',
        sort: 0,
        showInTable: false,
      },
      {
        label: 'Родительская папка',
        field: 'positionGroups',
        type: 'list-objects',
        sortField: 'positionGroups',
        sort: 0,
        showInTable: false,
      },
    ]
  },

  mounted() {
    const {id} = this.$route.params

    const width = window.innerWidth
    this.$store.commit('sidebars/changeLeftSidebar', width > 768)
    this.$store.commit('sidebars/changeRightSidebar', id !== undefined)
    eventBus.$on('update-settings', this.updateData)

    if (id) {
      eventBus.$emit('update-schema', {id: id})
      this.$store.commit('products/itemsForDelete', [id])
    }
  },

  methods: {
    uploadData() {
      if (!this.unloadingProcess) {
        this.unloadingProcess = true
        OtherService.uploadDataInCSV()
          .then(r => {
            this.downloadUrl = r.data.data['file_url']
            this.$toast.success(this.$t('The file is ready for downloading'))
            this.unloadingProcess = false
          })
          .catch(e => {
            errorHandler(e)
          })
      }
    },
    downloadData() {
      const fURL = document.getElementById('download-file')

      fURL.href = this.downloadUrl
      fURL.setAttribute('download', 'file.csv')

      fURL.click()
    },
    updateData() {
      ProductsService.getDirectoryItems()
    },
    loadNewData() {
      const page = this.$store.state.products.paginationPage
      if (this.$store.state.products.elements.length) {
        this.$store.commit('products/changePage', page + 1)
        ProductsService.getDirectoryItems(true)
      }
    },
    selectItem(data) {
      if (data.column.field !== 'checkbox') {
        this.$store.commit('sidebars/changeRightSidebar', !this.$store.state.products.isArchive)
        if (!this.$store.state.products.isArchive) eventBus.$emit('update-schema', data.row)
        this.$router.push({name: 'products-id', params: {id: data.row.id}})
      }
    },
    async returnFromArchiveProducts() {
      await ProductsService.archiveDirectoryItems('products')
      await ProductsService.getDirectoryItems()
    },
    archiveItems() {
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.state.products.selectedItemsForDelete.forEach(async (item, index, arr) => {
        if (index + 1 !== arr.length) {
          await ProductsService.deleteDirectoryItems()
        } else {
          await ProductsService.deleteDirectoryItems().then(r => {
            this.$toast.success(r.data['message'])
            ProductsService.getDirectoryItems()
          })
        }
      })
    },
    deleteItem() {
      this.$refs['table-container'].loadingDelete = true
      ProductsService.deleteDirectoryItems(true)
        .then(r => {
          this.$toast.success(r.data.message)
          this.$refs['table-container'].loadingDelete = false
          this.$refs['table-container'].showDeleteModal = false
          this.$store.commit('sidebars/changeRightSidebar', false)
          this.$store.commit('products/itemsForDelete', [])
          this.$refs.product.product = {
            schema: [],
            title: '',
          }
          eventBus.$emit('new-state', false)
          ProductsService.getDirectoryItems()
        })
        .catch(e => {
          errorHandler(e)
        })
    },
    copyItem() {
      this.$refs.product.createDirectoryItem(true)
    },
  },
  beforeDestroy() {
    this.$store.commit('products/updateElements', {data: []})
    this.$store.commit('products/updatePagination', {
      meta: {
        custom: {
          total: null,
        },
        pagination: {
          current_page: null,
          total_pages: null,
          total: null,
        },
      },
    })
    eventBus.$off('update-settings')
    eventBus.$off('update-settings-products')
  },
}
</script>
